import { settings } from './settings.js';
import { templating } from './tools.js';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

var process = function () {
	return {
		filterAction: function (filters) {
			return new Promise((resolve, reject) => {
				let countGroups = 0;
				let countFields = 0;
				let countFilters = 0;
				let passedFilters = 0;

				if (filters) {
					$.each(filters, function (group, fields) {
						let data;
						if (group === 'application') {
							data = settings.trip.application;
						} else {
							data = settings[group];
						}

						countGroups++;
						countFields += Object.keys(fields).length;

						$.each(fields, function (field, value) {
							if (field in data) {
								if (
									(Array.isArray(value) && $.inArray(data[field], value) !== -1) ||
									data[field] == value
								) {
									passedFilters++;
								}
							}
							countFilters++;
						});

						// Is the last, return promise
						if (countGroups === Object.keys(filters).length && countFields === countFilters) {
							if (countFilters === passedFilters) {
								resolve(true);
							} else {
								resolve(false);
							}
						}

					});

				} else {
					resolve(true);
				}
			});
		},
		formatAction: function (row, item) {
			let uploaded = false;

			// Templating
			$.each(['name', 'details', 'instructions', 'url'], function (i, name) {
				if (name in row && row[name] && row[name].search('{{') != -1) {
					row[name] = templating(row[name], {
						application: settings.trip.application,
						member: settings.member
					});
				}
			});

			switch (row.type) {
				case "link":
					item.find(".action-icon i").html(row.icon);
					item.find(".action-title").html(row.name);
					if (row.details) {
						item.find(".action-details").html(row.details);
					} else {
						item.find(".action-details").remove();
					}

					// Open Directly
					if (row.example || row.video || row.instructions) {
						item.attr("data-target", "upload-link");
						item.attr("data-target-args", JSON.stringify(row));
					} else {
						item.addClass('cursor-pointer');
						item.on('click', function () {
							window.open(row.url, '_blank', 'location=no');
						});
					}

					break;

				case "field":
					item.find(".action-title").html(row.name);
					if (row.details) {
						item.find(".action-details").html(row.details);
					} else {
						item.find(".action-details").remove();
					}

					let field_split = row.field.split(".");
					row.field_group = field_split[0];
					row.field_name = field_split[1];
					let field = field_split.reduce(function (prev, current) { return prev[current]; }, {
						member: settings.member,
						application: settings.trip.application
					});

					item.find(".action-icon i").html(row.icon);
					if (field) {
						item
							.find(".action-icon").addClass('border-green')
							.find("i").addClass("text-green");
						item.find('.action-checkbox').addClass('checked');
						uploaded = true;
					} else {
						item
							.attr("data-target", "upload-field")
							.attr("data-target-args", JSON.stringify(row));
					}

					break;

				case "file":
					item.addClass("step-file");
					item.find(".action-icon i").html(row.icon);
					item.find(".action-title").html(row.name);

					if (row.details || row.reason) {
						item.find(".action-details").html(row.details);
					} else {
						item.find(".action-details").remove();
					}

					if (row.status === 0 || row.status === 3) {
						item.attr("data-target", "upload-file");
						item.attr("data-target-args", JSON.stringify(row));
					} else if (row.status === 2) {
						item.attr("data-target", "viewer");
						item.attr(
							"data-target-args",
							JSON.stringify({
								urls: row.files,
								type: 'gallery',
								title: row.name
							})
						);
						item.find('.action-checkbox').addClass('checked');
					}

					if (!row.keepIcon) {
						switch (row.status) {
							case 1:
								item
								item
									.find(".action-icon").addClass('border-yellow')
									.find("i").html("hourglass_empty").addClass("text-yellow");
								break;
							case 2:
								item
									.find(".action-icon").addClass('border-green')
									.find("i").addClass("text-green");
								item.find('.action-checkbox').addClass('checked');
								uploaded = true;
								break;
							case 3:
								item
									.find(".action-icon").addClass('border-red')
									.find("i").addClass("text-red");
								item
									.find(".action-details")
									.append('<div class="text-red">' + row.reason + "</div>");
								break;
						}
					}
					break;

				case "form":
					item.find(".action-icon i").html(row.icon);
					item.find(".action-title").html(row.name);

					if (row.details || row.reason) {
						item.find(".action-details").html(row.details);
					} else {
						item.find(".action-details").remove();
					}

					if (row.status === 0 || row.status === 3) {
						item.attr("data-target", "upload-form");
						item.attr("data-target-args", JSON.stringify(row));
					}

					if (!row.keepIcon) {
						switch (row.status) {
							case 1:
								item
									.find(".action-icon").addClass('border-yellow')
									.find("i").html("hourglass_empty").addClass("text-yellow");
								break;
							case 2:
								item
									.find(".action-icon").addClass('border-green')
									.find("i").addClass("text-green");
								item.find('.action-checkbox').addClass('checked');
								uploaded = true;
								break;
							case 3:
								item
									.find(".action-icon").addClass('border-red')
									.find("i").addClass("text-red");
								item
									.find(".action-details")
									.append('<div class="text-red">' + row.reason + "</div>");
								break;
						}
					}
					break;

				case "post":
					item.find(".action-icon i").html(row.icon);
					item.find(".action-title").html(row.name);
					if (row.details) {
						item.find(".action-details").html(row.details);
					} else {
						item.find(".action-details").remove();
					}
					item.attr("data-target", "reader");
					item.attr("data-target-args", JSON.stringify({
						title: row.name,
						content: row.instructions
					}));

					break;
			}

			// Deadline Progress
			let deadline = item.find(".action-deadline");
			if (
				!uploaded &&
				row.enrollment &&
				(row.deadline_days || row.deadline_date)
			) {
				let start = moment(row.enrollment),
					end, total, left;
				if (row.deadline_days) {
					end = moment(row.enrollment);
					end.add(row.deadline_days, "days");
				} else if (row.deadline_date) {
					end = moment(row.deadline_date);
				}
				total = end.diff(start, "days");
				left = end.diff(moment(), "days");
				if (left < 0) left = 0;
				let plural = left === 1 ? false : true;
				deadline
					.find(".days")
					.html("queda" + (plural ? "n" : "") + " " + left + " " + "día" + (plural ? "s" : ""));

				let progress = Math.round(100 - (left / total) * 100);
				let progressColor = progress < 50 ? "#00c566" : "#c50300";
				deadline.css("background", "linear-gradient(90deg, " + progressColor + " " + progress + "%, #ddd 0%)");
			} else {
				deadline.remove();
			}

			return item;
		}
	};
};

export { process }
