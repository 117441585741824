import { settings, videoTutorialOptions } from '../libraries/settings.js';
import { toast } from '../libraries/toast.js';
import { db } from '../libraries/db.js';

import YouTubePlayer from 'youtube-player';

App.controller('upload-file', function (page, data) {
    this.restorable = false;

    // Title & Details
    $(page).find(".upload-title").append(data.name);
    if (data.instructions) {
        $(page).find(".upload-details").html(data.instructions);
    } else {
        $(page).find(".upload-details").remove();
    }

    // Template
    if (data.template) {
        $(page).find('.button-seedoc')
            .attr('data-target-args', JSON.stringify({
                file: data.template,
                folder: 'others/actions',
                type: 'file',
            }));

        $(page).find('.button-emaildoc').on('click', function () {
            $(page).addClass('app-block-touch');
            toast().show('Enviando...', 'warning');

            fetch(settings.restUrl + '/uploads/emaildoc', {
                method: 'POST',
                headers: {
                    'X-WETAG-Token': settings.token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    member_id: settings.member.member_id,
                    step_file_id: data.step_file_id
                })
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    toast().show(response.html, response.class);
                    $(page).removeClass('app-block-touch');
                })
                .catch(error => {
                    $(page).removeClass('app-block-touch');
                });

        });
    } else {
        $(page).find('.upload-template').remove();
    }

    // Does Not Require Upload
    if (data.requires_upload != 1) {
        $(page).find('.upload-file-trigger, .upload-step-file, .upload-files-list').remove();
        $(page).find('.app-bottom-nav .app-nav').addClass('app-nav-xs');
    }

    // Delete Previous Files Uploaded
    if (navigator.onLine) {
        fetch(settings.restUrl + '/uploads/empty', {
            method: 'POST',
            headers: {
                'X-WETAG-Token': settings.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                path: settings.member.member_id + '/' + settings.trip.application.application_id + '/' + data.step_file_id
            })
        });
    }

    // Add File
    var images = [];

    function selectFile(image) {
        let c = $(page).find('.upload-files-list > li').length;
        let ext = image.name.substring(image.name.lastIndexOf('.') + 1).toLowerCase();
        let node = $('<li>', {
            class: 'animate__animated animate__flipInX',
            'data-i': c
        });

        let reader = new FileReader();
        reader.onload = function (e) {
            let file;
            if (/^(jpg|jpeg|png|gif)$/.test(ext)) {
                file = $('<img>', {
                    src: e.target.result,
                    class: 'img-fluid'
                });
            } else {
                file = $('<iframe>', {
                    src: e.target.result,
                    type: 'application/pdf'
                });
            }
            node.append(file);
        };
        reader.readAsDataURL(image);
        images.push(image);

        node.on('click', function (e) {
            e.stopImmediatePropagation();
            let element = $(this);
            App.dialog({
                title: 'Borrar Archivo ?',
                okButton: 'Aceptar',
                cancelButton: 'Cancelar',
            },
                function (confirmed) {
                    if (confirmed) {
                        images.splice(element.data('i'), 1);
                        element.remove();
                    }
                }
            );
        });

        $(page).find('.upload-files-list').prepend(node);
    }

    $(page).find(".upload-file-trigger").on('click', function () {
        $(page).find('.type-' + $(this).data('type')).trigger('click');
    });

    $(page).find('.upload-file-input').on('change', function (e) {
        selectFile(this.files[0]);
        this.value = '';
    });

    // Upload File
    let isUploading = false;
    $(page)
        .find(".upload-step-file")
        .on("click", function () {
            if (isUploading) return false;
            isUploading = true;

            $(page).addClass('app-block-touch');
            if (images.length > 0) {
                toast().show("<b>Cargando Archivos</b>", "info", true, false, "uploading");

                // Upload Images
                let filesProcessed = 0;
                let filesUploaded = 0;
                let filesErrors = [];
                let filesUploading = new Promise((resolve, reject) => {
                    images.forEach(function (file, i) {
                        const c = i + 1;
                        $('.app-toast.uploading').append('<br>Archivo ' + c + ' <span class="file-' + c + '">0%</span>');

                        let formData = new FormData();
                        formData.append('file', file);
                        formData.append('folder', data.step_file_id);
                        formData.append('member_id', settings.member.member_id);
                        formData.append('application_id', settings.trip.application.application_id);

                        var ajax = new XMLHttpRequest();
                        ajax.upload.addEventListener('progress', function (e) {
                            if (e.lengthComputable) {
                                let status = e.loaded / e.total;
                                status = parseInt(status * 100);
                                $('.app-toast.uploading span.file-' + c).html(status + '%');
                            }
                        }, false);

                        ajax.addEventListener('load', function (e) {
                            filesProcessed++;
                            const response = JSON.parse(e.currentTarget.response);

                            if (response.class === 'success') {
                                filesUploaded++;
                            } else {
                                $('.app-toast.uploading span.file-' + c).html('Error');
                                filesErrors.push('Archivo ' + c + ': ' + response.html);
                            }

                            if (filesProcessed === images.length) {
                                if (filesUploaded === filesProcessed) {
                                    resolve(true);
                                } else {
                                    reject({
                                        class: 'danger',
                                        html: 'Error al cargar algunos de los Archivos.<br>' + filesErrors.join('<br>')
                                    });
                                }
                            }
                        }, false);

                        ajax.addEventListener('error', function () {
                            $('.app-toast.uploading span.file-' + c).html('Error');
                        }, false);

                        ajax.open('POST', settings.restUrl + '/application/' + settings.trip.application.application_id + '/upload');
                        ajax.setRequestHeader('X-WETAG-Token', settings.token);
                        ajax.send(formData);

                    });
                });


                // PDFit
                filesUploading
                    .then(function () {
                        $(page).removeClass('app-block-touch');
                        $('.app-toast.uploading').remove();

                        fetch(settings.restUrl + '/application/' + settings.trip.application.application_id + '/pdfit', {
                            method: 'POST',
                            headers: {
                                'X-WETAG-Token': settings.token,
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: new URLSearchParams({
                                member_id: settings.member.member_id,
                                filename: data.name,
                                step_file_id: data.step_file_id
                            })
                        })
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                App.load('application', { tripId: db().getCurrentTripId() });
                                toast().show(response.html, response.class);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    })
                    .catch(function (response) {
                        App.load('application', { tripId: db().getCurrentTripId() });
                        toast().show(response.html, response.class);
                    });
            } else {
                isUploading = false;
                $(page).removeClass('app-block-touch');
            }
        });

    $(page).on('appReady', function () {
        // Photo Example
        if (data.example) {
            $(page)
                .find(".upload-example")
                .html('<img src="' + settings.contentUrl + '/others/actions/' + data.example + '" class="img-fluid rounded">')
                .show();
        } else {
            $(page).find(".upload-example").remove();
        }

        // Video Tutorial
        if (data.video) {
            let player = YouTubePlayer("upload-video", Object.assign({
                videoId: data.video,
                width: $(page).find("#upload-video").width()
            }, videoTutorialOptions));
            $(page).find(".upload-video").css('visibility', 'visible');
        } else {
            $(page).find(".upload-video").remove();
        }

        $(page).find('.app-loader').fadeOut();
    });
});
