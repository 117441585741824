import { settings } from '../libraries/settings.js';
import { toast } from '../libraries/toast.js';
import { db } from '../libraries/db.js';

App.controller('upload-field', function (page, data) {
    this.restorable = false;

    let fields = {};

    fields[data.field_name] = {
        label: data.name,
        type: data.field_type,
        required: true,
        col: 12,
    };
    $(page).find(".form-wrap").form(fields);

    $(page).find(".upload-title").append(data.name);
    if (data.instructions) {
        $(page).find(".upload-details").html(data.instructions);
    } else {
        $(page).find(".upload-details").remove();
    }

    $(page).on('appReady', function () {
        $(page).find('.app-loader').fadeOut();

        let isUploading = false;
        $(page)
            .find(".upload-step-field")
            .on("click", function () {
                if (!isUploading) $(page).find(".form-form").trigger("submit");
            });

        $(page)
            .find(".form-form")
            .on("submit", function (e) {
                if (isUploading) return false;
                isUploading = true;
    
                e.preventDefault();
                e.stopPropagation();

                $(page).addClass('app-block-touch');
                if ($(this).isValid()) {
                    toast().show('Guardando...', 'warning');

                    let field_group_id;
                    if (data.field_group == "application") {
                        field_group_id = settings.trip.application.application_id;
                    } else if (data.field_group == "member") {
                        field_group_id = settings.member.member_id;
                    }

                    fetch(settings.restUrl + '/' + data.field_group + '/' + field_group_id, {
                        method: 'POST',
                        headers: {
                            'X-WETAG-Token': settings.token,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: $(this).serialize()
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            App.load('application', { tripId: db().getCurrentTripId() });
                            $(page).removeClass('app-block-touch');
                            toast().show(response.html, response.class);
                        })
                        .catch(error => {
                            isUploading = false;
                            $(page).removeClass('app-block-touch');
                        });
                } else {
                    isUploading = false;
                    $(page).removeClass('app-block-touch');
                }

            });
    });
});
