import { settings } from '../libraries/settings.js';
import { toast } from '../libraries/toast.js';
import { forms } from '../libraries/forms.js';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

App.controller('upload-payment', function (page, data) {
    this.restorable = false;

    // Add File
    var images = [];

    function selectFile(image) {
        let ext = image.name.substring(image.name.lastIndexOf('.') + 1).toLowerCase();
        let node = $('<li>', {
            class: 'animate__animated animate__flipInX',
            'data-i': 0
        });

        let reader = new FileReader();
        reader.onload = function (e) {
            let file;
            if (/^(jpg|jpeg|png|gif)$/.test(ext)) {
                file = $('<img>', {
                    src: e.target.result,
                    class: 'img-fluid'
                });
            } else {
                file = $('<iframe>', {
                    src: e.target.result,
                    type: 'application/pdf'
                });
            }
            node.append(file);

            // Only 1 file allowed
            $(page).find('.upload-file-trigger').addClass('d-none');
        };
        reader.readAsDataURL(image);
        images.push(image);


        node.on('click', function (e) {
            e.stopImmediatePropagation();
            let element = $(this);
            App.dialog({
                title: 'Borrar Archivo ?',
                okButton: 'Aceptar',
                cancelButton: 'Cancelar',
            },
                function (confirmed) {
                    if (confirmed) {
                        images.splice(element.data('i'), 1);
                        element.remove();

                        $(page).find('.upload-file-trigger').removeClass('d-none');
                    }
                }
            );
        });

        $(page).find('.upload-files-list').prepend(node);
    }

    $(page).find(".upload-file-trigger").on('click', function () {
        $(page).find('.type-' + $(this).data('type')).trigger('click');
    });

    $(page).find('.upload-file-input').on('change', function (e) {
        selectFile(this.files[0]);
        this.value = '';
    });

    // Upload Payment
    let isUploading = false;
    $(page)
        .find(".upload-payment")
        .on("click", function () {
            if (isUploading) return false;
            isUploading = true;
            
            let form = $(page).find('.payment-form');
            let member_id = settings.member.member_id;
            let application_id = settings.trip.application.application_id;

            $(page).addClass('app-block-touch');
            if (form.isValid()) {

                if (images.length === 1) {
                    toast().show('<span>Cargando Pago</span><span class="file-1">0%</span>', 'info', true, false, 'uploading d-flex justify-content-between');

                    // Upload Image
                    let formData = new FormData();
                    formData.append('file', images[0]);
                    formData.append('member_id', member_id);
                    formData.append('application_id', application_id);

                    formData.append('date', form.find('#field-date').val());
                    formData.append('method', form.find('#field-method option:selected').val());
                    formData.append('amount', form.find('#field-amount').val());


                    var ajax = new XMLHttpRequest();

                    ajax.upload.addEventListener('progress', function (e) {
                        if (e.lengthComputable) {
                            let status = e.loaded / e.total;
                            status = parseInt(status * 100);
                            $('.app-toast.uploading span.file-1').html(status + '%');
                        }
                    }, false);

                    ajax.addEventListener('load', function (e) {
                        $('.app-toast.uploading').remove();
                        try {
                            let response = JSON.parse(this.response);
                            if (response.class == 'success') {
                                App.load('application', { tripId: application_id });
                            }
                            toast().show(response.html, response.class);
                        } catch (error) {
                            isUploading = false;
                            $(page).removeClass('app-block-touch');
                            toast().show('Error: ' + error, 'danger');
                        }
                    }, false);

                    ajax.addEventListener('error', function () {
                        isUploading = false;
                        $(page).removeClass('app-block-touch');
                        $('.app-toast.uploading span.file-1').html('Error');
                    }, false);

                    ajax.open('POST', settings.restUrl + '/application/' + application_id + '/payment');
                    ajax.setRequestHeader('X-WETAG-Token', settings.token);
                    ajax.send(formData);

                } else if (images.length > 1) {
                    isUploading = false;
                    $(page).removeClass('app-block-touch');
                    toast().show('Solo se puede adjuntar 1 comprobante.', 'warning');
                } else {
                    isUploading = false;
                    $(page).removeClass('app-block-touch');
                    toast().show('Adjuntá el comprobante para continuar.', 'warning');
                }

            } else {
                isUploading = false;
                $(page).removeClass('app-block-touch');
            }
        });

    $(page).on('appReady', function () {

        $(page).find('.form-payment-wrap')
            .form(forms.payment, { id: 'payment' })
            .fill({ date: moment().format('YYYY-MM-DD') });

        $(page).find(".payment-form").on("submit", function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (!isUploading) $(page).find(".upload-payment").trigger('click');
        });

        $(page).find('.app-loader').fadeOut();
    });
});
