import { settings } from '../libraries/settings.js';
import { db } from '../libraries/db.js';

import { toast } from '../libraries/toast.js';
import { uploader } from '../libraries/uploader.js';

import gtag from 'ga-gtag';

App.controller('tournament', function (page, data) {
    this.restorable = false;

    $(page).find('.sticker').on('mouseover touchstart', function () {
        $(this).addClass('touchstart');
    });
    $(page).find('.sticker').on('mouseout touchend', function () {
        $(this).removeClass('touchstart');
    });


    $(page).on('appReady', function () {
        gtag('event', 'screen_view', { screen_name: 'Copa' });

        db().getTrip().then(function (data) {

            if (data) {
                let application = data.application;

                // Look for Host
                if (application.host_id) {

                    $(page).find('.group-name').html(application.host_label);

                    // Retrieve Stickers Uploaded
                    fetch(settings.restUrl + '/uploads/tournament/' + application.host_id, {
                        cache: 'no-cache',
                        headers: {
                            'X-WETAG-Token': settings.token
                        }
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if (response.files) {
                                $.each(response.files, function (i, name) {
                                    let sticker = $(page).find('.sticker[data-i="' + i + '"]');
                                    let img = new Image();

                                    sticker.html('Cargando...');
                                    img.onload = function () {
                                        sticker
                                            .html('').addClass('uploaded')
                                            .css('background-image', 'url(' + img.src + ')');
                                    };
                                    img.src = settings.contentUrl + '/others/copa/' + name;
                                });
                            }
                        });

                    // Stickers
                    $(page).find('.sticker').on('click', function () {
                        const i = $(this).data('i');
                        if ($(this).hasClass('uploaded')) {
                            toast().show('Si subis otra foto va a reemplazar la que ya existe.', 'warning');
                        }

                        uploader().open({
                            limit: 1,
                            endpoint: '/upload/tournament',
                            rename: '2023-' + application.host_id + '-' + i,
                            accept: 'image/*,video/*',
                            success: function (thumbnails) {
                                $(page).find('.sticker[data-i="' + i + '"]')
                                    .html('').addClass('uploaded')
                                    .css('background-image', 'url(' + thumbnails[0] + ')');
                            }
                        });
                    });

                } else {
                    toast().show('No tenes ningún empleador asignado. Escribinos para que podamos ayudarte.', 'danger', true);
                    return false;
                }
                
                if (application.near_host.length > 0) {
                    $.each(application.near_host, function (i, row) {
                        let item = $('<div>', {
                            class: 'app-avatar rounded-circle d-flex justify-content-center align-items-center flex-shrink-0',
                            'data-name': row.relation_nickname
                        });

                        if (row.relation_avatar) {
                            let relation_url = [settings.contentUrl, 'participants', row.relation_member_id, row.relation_avatar].join('/');
                            let img = new Image();

                            item.html('<i class="material-icons">hourglass_empty</i>');
                            img.onload = function () {
                                item.html('').css('background-image', 'url(' + img.src + ')');
                            };
                            img.src = relation_url;
                        } else {
                            item.html(row.relation_nickname);
                        }

                        $('.tournament-group').append(item);
                    });
                } else {
                    $('.tournament-group').remove();
                }
            }

            $(page).find('.app-loader').fadeOut();
        });

        $(page).find('.app-collapse .app-button').on('click', function () {
            $(this).parent().toggleClass('open');
        });


    });
});