import { toast } from '../libraries/toast.js';

let nsw;

function updateSWtoast(sw) {
    toast().show("Hay una nueva versión disponible, hacé click acá para actualizar.", "warning", true, function () {
        $('.app-loader').fadeIn();
        sw.postMessage('skipWaiting');

        window.location.replace(window.location.href);
    });
}

if ('serviceWorker' in navigator) {

    window.addEventListener('load', async () => {
        let swUrl = new URL('../service-worker.js', import.meta.url);
        navigator.serviceWorker
            .register(swUrl.origin + swUrl.pathname, { type: 'module' })
            .then((registration) => {

                if (registration.waiting) {
                    updateSWtoast(registration.waiting);
                }

                registration.addEventListener('updatefound', () => {
                    nsw = registration.installing;

                    nsw.addEventListener('statechange', () => {
                        console.log(nsw.state);

                        switch (nsw.state) {
                            case 'installed':
                                if (navigator.serviceWorker.controller) {
                                    updateSWtoast(registration.waiting);
                                }
                                break;
                            case 'activated':
                                window.location.replace(window.location.href);
                                break;
                        }
                    });

                });

            })
            .catch((err) => console.error('Service Worker Error', err));

        navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.replace(window.location.href);
        });

        /*
        Background Sync
         async function registerContentSync() {
            const registration = await navigator.serviceWorker.ready;
            try {
                await registration.periodicSync.register('content-sync', {
                    minInterval: 24 * 60 * 60 * 1000, // 1 day
                });
            } catch (error) {
                console.log('Background Sync Error: ' + error);
            }
        }
        const registration = await navigator.serviceWorker.ready;
        if ('periodicSync' in registration) {
            const status = await navigator.permissions.query({
                name: 'periodic-background-sync',
            });

            if (status.state === 'granted') {
                registerContentSync();
            } else {
                toast().show("Activá la sincronización periodica para tener actualizado tu contenido siempre.", "warning", true, function () {
                    const status = navigator.permissions.query({
                        name: 'periodic-background-sync',
                    });
                    if (status.state === 'granted') {
                        registerContentSync();
                    }
                });
            }
        }
        */

    });
}