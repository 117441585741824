import { settings } from '../libraries/settings.js';
import { auth } from '../libraries/auth.js';
import { db } from '../libraries/db.js';

import { tools, templating, throwConfetti } from '../libraries/tools.js';
import { toast } from '../libraries/toast.js';
import { process } from '../libraries/process.js';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import localforage from 'localforage/dist/localforage.min.js';

import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
import PullToRefresh from 'pulltorefreshjs';

App.controller('application', function (page, data) {
	auth().checkToken();
	$(page).on('appReady', function () {
		gtag('event', 'screen_view', { screen_name: 'Viaje' });
		tools().setPexelBackground('.section-hero');

		// Install Dialog
		var pwaInstall = document.getElementsByTagName('pwa-install')[0];
		localforage.getItem('installDialogHidden').then(function (value) {
			if (value === null) {
				pwaInstall.showDialog();
				localforage.setItem('installDialogHidden', true);
			}
		});

		if (!pwaInstall.isUnderStandaloneMode) {
			$(page).find('.button-install').on('click', function () {
				pwaInstall.showDialog();
			});
		} else {
			$(page).find('.button-install').remove();
		}

		let nodeItem = $(page).find('.step-actions-todo-list > li.app-card');
		let nodeLabel = $(page).find('.step-actions-todo-list > li.label');
		nodeItem.remove();
		nodeLabel.remove();

		// Get Trip
		let tripId = data.tripId || false;
		db().getTrip(tripId).then(function (data) {
			$(page).find('.app-loader-w').fadeOut();

			if (data) {
				db().getMember().then(function (member) {
					let application = data.application;
					let step = data.step;

					tools().showSupportNav(page);

					// Headers
					$(page).find('.member-name').html(application.member_nickname || application.member_name);
					$(page).find('.step-name').html(step.name);
					$(page).find('.application-program').prepend(application.program_label);
					$(page).find('.application-subprogram').prepend(application.subprogram_label);

					if (application.member_avatar) {
						let avatar_url = [settings.contentUrl, 'participants', application.member_id, application.member_avatar].join('/');
						if (tripId) avatar_url += '?' + moment().format('X');

						$(page).find('.step-header-avatar').css('background-image', 'url("' + avatar_url + '")');
					} else {
						$(page).find('.step-header-avatar').remove();
					}

					if (application.enrollment) {
						var enrollmentDate = moment(application.enrollment);
						$(page)
							.find('.application-enrollment-wrap').removeClass('d-none')
							.find('.application-enrollment').html(enrollmentDate.format('D [de] MMMM, YYYY'));
					} else {
						$(page).find('.application-enrollment-wrap').remove();
					}

					// Relations
					if (application.relations) {
						$('.member-relations').empty();

						$.each(application.relations, function (i, row) {
							let item = $('<div>', {
								class: 'app-avatar rounded-circle d-flex justify-content-center align-items-center flex-shrink-0',
								'data-name': row.relation_nickname
							});
							if (row.relation_avatar) {
								let relation_url = [settings.contentUrl, 'participants', row.relation_member_id, row.relation_avatar].join('/');
								let img = new Image();

								item.html('<i class="material-icons">hourglass_empty</i>');
								img.onload = function () {
									item.html('').css('background-image', 'url(' + img.src + ')');
								};
								img.src = relation_url;
							} else {
								item.html(row.relation_nickname);
							}
							$('.member-relations').append(item);
						});
					} else {
						$('.member-relations').remove();
					}

					// Near Destination
					/*
					if (application.near_destination) {
						$.each(application.near_destination, function (i, row) {
							let item = $('<div>', {
								class: 'app-avatar rounded-circle d-flex justify-content-center align-items-center flex-shrink-0',
								'data-name': row.relation_nickname
							});
	
							if (row.relation_avatar) {
								let relation_url = [settings.contentUrl, 'participants', row.relation_member_id, row.relation_avatar].join('/');
								let img = new Image();
							   
								item.html('<i class="material-icons">hourglass_empty</i>');
								img.onload = function () {
									item.html('').css('background-image', 'url(' + img.src + ')');
								};
								img.src = relation_url;
							} else {
								item.html(row.relation_nickname);
							}
	
							if (row.relation_phone) {
								
								item.on('click', function () {
									window.open('whatsapp://send?phone=' + ((/^54/.test(row.relation_phone) === false) ? 549 : '') + row.relation_phone, '_system', 'hidden=yes');
								});
							}
	
							$('.member-near-destination').append(item);
						});
					} else {
						$('.member-near-destination').remove();
					}
					*/
					$('.member-near-destination').remove();

					// Tournament
					/*
					if (application.year === 2023 && [1, 2].includes(application.subprogram_id)) {
						$(page).find('.wrap-copa').removeClass('d-none');
					} else {
						$(page).find('.wrap-copa').remove();
					}
					*/

					// Step
					switch (data.step.type) {
						case 'form':
							console.log(data);
							if (step.details) {
								$(page).find('.step-details .section-body').html(templating(step.details, {
									application: application,
									member: member
								}));
							} else {
								$(page).find('.step-details').remove();
							}

							let formWrap = $('<div>', {
								class: 'form-wrap mt-5'
							});
							formWrap.form(data.step.form_fields, { id: data.step.form_name });
							$(page).find('.step-actions-payments').before(formWrap);

							$(page).find(".step-documents, .step-actions-todo-list-title, .step-actions-todo-list, .step-actions-done-list").remove();
							break;
						case 'content':
							if (step.details) {
								$(page).find('.step-details .section-body').html(templating(step.details, {
									application: application,
									member: member
								}));
							} else {
								$(page).find('.step-details').remove();
							}
							$(page).find(".step-documents, .step-actions-todo-list-title, .step-actions-todo-list, .step-actions-done-list").remove();
							break;
						case 'contenttasks':
							if (step.details) {
								$(page).find('.step-details .section-body').html(templating(step.details, {
									application: application,
									member: member
								}));
							} else {
								$(page).find('.step-details').remove();
							}

						case 'tasks':
							if (data.step.type === 'tasks') $(page).find('.step-details').remove();

							// Actions
							let listActionsToDo = $(page).find(".step-actions-todo-list");
							if (data.todo && Object.keys(data.todo).length > 0) {

								let label = nodeLabel.clone();
								$.each(data.todo, function (i, row) {
									process().filterAction(row.filters).then(function (result) {
										if (result) {
											let item = nodeItem.clone();
											item.addClass('step-todo');
											if (application.enrollment) row.enrollment = application.enrollment;

											listActionsToDo.append(process().formatAction(row, item));
										}
									});
								});
							} else {
								$(page).find('.step-actions-todo-list-title').remove();
								listActionsToDo.remove();
							}


							let listActionsSticky = $(page).find(".step-actions-sticky-list");
							let nodeStickyItem = listActionsSticky.find('.app-card');
							let listActionsDone = $(page).find(".step-actions-done-list");
							let totalActionsDone = Object.keys(data.done).length;
							let countActions = 0;
							var countActionsSticky = 0;
							nodeStickyItem.remove();

							if (data.done && totalActionsDone > 0) {
								$.each(data.done, function (i, row) {
									process().filterAction(row.filters)
										.then(function (result) {
											if (result) {
												if (application.enrollment) row.enrollment = application.enrollment;

												if (row.sticky === 1) {
													row.keepIcon = true;
													let item = nodeStickyItem.clone();
													listActionsSticky.append(process().formatAction(row, item));

													// Save to Cache
													if (row.files && row.files.length > 0) {
														caches.open(settings.version).then((cache) => {
															row.files.forEach((file) => cache.add(file));
														});
													}

													countActionsSticky++;
												} else {
													let item = nodeItem.clone();
													item.addClass('step-done');
													item.find('.action-checkbox, .action-legend').remove();

													listActionsDone.append(process().formatAction(row, item));
												}
											} else {
												countActions++;
											}
										})
										.then(function () {
											countActions++;

											$(page).find('.button-show-done .q').html(countActions);
											if (countActions === totalActionsDone) {
												if (countActionsSticky === 0) {
													$(page).find(".step-documents").remove();
												} else {
													new Flickity('.step-actions-sticky-list', {
														prevNextButtons: false,
														pageDots: false,
														contain: true,
														cellAlign: 'left',
														dragThreshold: 10,
														draggable: '>1',
														friction: 0.3
													});
												}
											}
										});
								});

								$(page).find('.button-show-done').on('click', function () {
									const status = $(this).data('status');
									if (status === 'open') {
										$(this).data('status', 'closed');
										$(this).find('i').html('expand_more')
									} else {
										$(this).data('status', 'open');
										$(this).find('i').html('expand_less')
									}
									listActionsDone.toggleClass('hide-items');
								});
							} else {
								listActionsDone.remove();
								$(page).find(".step-documents").remove();
							}
							break;
					}

					// Payments
					let titlePayments = $(page).find(".step-actions-payments-title");
					let listPayments = $(page).find(".step-actions-payments-list");
					let nodePaymentItem = listPayments.find('.app-card:last-child');
					nodePaymentItem.remove();

					let stepPayments = $(page).find('.step-payments');
					stepPayments.html(templating(stepPayments.html(), {
						payments: {
							price: application.price,
							paid: application.paid + application.discounted,
							balance: Math.round(application.price - application.paid - application.discounted)
						}
					}));

					if (data.payments.length > 0) {
						$.each(data.payments, function (i, row) {
							let item = nodePaymentItem.clone();
							let isReturn = row.component == 'return';

							if (row.eta > 0) {
								item
									.find(".action-icon").addClass('border-green')
									.find("i").html(isReturn ? 'replay' : 'receipt').addClass("text-green");
							} else {
								item
									.find(".action-icon").addClass('border-yellow')
									.find("i").html("hourglass_empty").addClass("text-yellow");
							}

							item.find('.action-title').html('U$D ' + row.gamma + (isReturn ? ' devueltos por ' : ' pagados con ') + row.method_label);
							item.find('.action-details').html('Pagado el ' + moment(row.date).format('DD [de] MMMM [del] YYYY') + ' en concepto de ' + row.note);

							item.find('.action-legend, .action-checkbox').remove();
							listPayments.append(item);
						});
					} else {
						listPayments.append('<li class="text-muted ms-3">Todavía no tenés pagos cargados</li>');
					}

					if (application.enrollment && parseFloat(application.overdueBalance) > 99) {
						$('<div>', {
							class: "p-3 m-3 rounded bg-white border border-2 border-warning",
							html: '<b>SALDO VENCIDO U$D ' + application.overdueBalance + '</b><br>Nuestros registros indican que estás adeudando pagos. Por favor regularizá tu situación para conservar tu cupo en el programa o escribinos si es un error.'
						})
							.insertAfter(titlePayments)
					}


					if (data.step.payment) {
						$(page).find('.button-payment-info').on('click', function () {
							App.load('reader', {
								title: 'Formas de Pago ',
								content: data.step.payment
							});
						});
					} else {
						$(page).find('.button-payment-info').parent().remove();
					}

					// Terms
					if (step.terms) {
						$(page).find('.step-terms')
							.attr("data-target-args", JSON.stringify({
								title: 'Condiciones del paso: ' + step.name,
								content: step.terms
							}));
					} else {
						$(page).find('.step-terms').remove();
					}
				});

				// Content
				let listContent = $(page).find('.content-gallery');
				var fetchPostsHeaders = new Headers();
				fetchPostsHeaders.append('pragma', 'no-cache');
				fetchPostsHeaders.append('cache-control', 'no-cache');
				fetch('https://wetag.ar/wp-json/wp/v2/posts?categories=21&posts_per_page=10&_embed&_' + new Date().getTime())
					.then((response) => {
						return response.json();
					})
					.then((posts) => {
						listContent.empty();
						if (posts.length > 0) {
							$.each(posts, function (i, post) {
								let coverSizes = 'wp:featuredmedia' in post._embedded ? post._embedded['wp:featuredmedia'][0].media_details.sizes : false;
								let cover = false;
								if (coverSizes) {
									if ('medium' in coverSizes) {
										cover = coverSizes.medium.source_url;
									} else {
										cover = coverSizes.full.source_url;
									}
								}

								let item = $('<section>', {
									class: 'post drop-shadow-dark-left',
									html: '<h3 class="m-0 p-2 text-white">' + post.title.rendered + '</h3>',
								});
								if (cover) item.css('background-image', 'url(' + cover + ')');

								item.data('args', {
									title: post.title.rendered,
									excerpt: post.excerpt.rendered,
									content: post.content.rendered,
									date: post.modified,
									cover: cover,
									acf: post.acf
								});

								listContent.append(item);
							});
						}
					})
					.then(function () {
						var flkty = new Flickity(listContent[0], {
							prevNextButtons: false,
							pageDots: false,
							cellAlign: 'left',
							contain: true,
							dragThreshold: 10,
							friction: 0.3
						});
						flkty.on('staticClick', function (event, pointer, element) {
							App.load('reader', $(element).data('args'))
						});

					})
					.catch(error => {
						console.log(error);
					});
			}

		});

		// Pull to Refresh
		localforage.getItem('trip').then(function (table) {
			let expired = table ? moment().diff(table.timestamp, 'minutes') > 1 : true;
			$(page).find('.button-refresh-time ').html('actualizado ' + moment(table.timestamp).fromNow())
		});

		PullToRefresh.init({
			mainElement: '.app-content',
			triggerElement: '.page-application',
			instructionsPullToRefresh: 'Arrastrá hacia abajo para actualizar',
			instructionsReleaseToRefresh: 'Soltá para actualizar',
			instructionsRefreshing: 'Actualizando',
			onRefresh() {
				App.load('application', { tripId: db().getCurrentTripId() });
			},
			shouldPullToRefresh: function () {
				return !this.mainElement.scrollTop;
			}
		});


		$(page).find('.button-refresh').on('click', function () {
			App.load('application', { tripId: db().getCurrentTripId() });

		});
	});
});
